/* eslint-disable no-console */

import { datadogLogs, Logger } from "@datadog/browser-logs";
import config from "@/config";

datadogLogs.init({
	clientToken: config.DATADOG_CLIENT_KEY,
	datacenter: "eu",
	isCollectingError: true,
	sampleRate: 100,
	forwardErrorsToLogs: true,
});

datadogLogs.addLoggerGlobalContext("env", config.ENVIRONMENT);
datadogLogs.addLoggerGlobalContext("service", "dealer-portal");
datadogLogs.logger.setLevel(
	config.ENVIRONMENT === "production" ? "warn" : "info"
);

if (config.LOG_TO_DATADOG !== "true") {
	const consoleHandler = (message) => {
		console.log(`${message.status}: ${message.message}`, message);
	};

	datadogLogs.logger = new Logger(datadogLogs.logger.session, {
		console: consoleHandler,
	});
	datadogLogs.logger.setHandler("console");
}

export default datadogLogs.logger;
