export default function mapIconName(iconName) {
	switch (iconName) {
		case "eye-slash":
			return "eye-off";
		case "angle-left":
			return "chevron-left";
		case "angle-right":
			return "chevron-right";
		case "caret-down":
			return "chevron-down";
		case "caret-up":
			return "chevron-up";
		case "info-circle":
			return "info";
		case "exclamation-circle":
			return "alert-circle";
		case "exclamation-triangle":
			return "alert-triangle";
		case "times":
			return "x";
		case "map-marker-alt":
			return "map-pin";
		default:
			return iconName;
	}
}
