import VueI18n from "vue-i18n";
import daDK from "./da-dk.json";
import deDE from "./de-de.json";
import enGB from "./en-gb.json";
import esES from "./es-es.json";
import fiFI from "./fi-fi.json";
import frFR from "./fr-fr.json";
import itIT from "./it-it.json";
import nbNO from "./nb-no.json";
import nlNL from "./nl-nl.json";
import plPL from "./pl-pl.json";
import svSE from "./sv-se.json";

const i18n = new VueI18n({
	locale: "en-GB",
	fallbackLocale: "en-GB",
	messages: {
		"da-DK": daDK,
		"de-DE": deDE,
		"en-GB": enGB,
		"es-ES": esES,
		"fi-FI": fiFI,
		"fr-FR": frFR,
		"it-IT": itIT,
		"nb-NO": nbNO,
		"nl-NL": nlNL,
		"pl-PL": plPL,
		"sv-SE": svSE,
	},
});

export default i18n;
