/* eslint-disable no-param-reassign */

function update(el) {
	if (el.tagName !== "INPUT") {
		// get internal input for components like b-input which have container as main element
		el = el.querySelector("input");
	}
	el.value = el.value.toUpperCase();
	el.dispatchEvent(new Event("input")); // recalculate v-model
}

export default {
	update,
};
