import Vue from "vue";
import "./i18n";
import Buefy from "buefy";

// Import Vue FilePond
import vueFilePond, { setOptions } from "vue-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";
import "filepond-polyfill";

// Import image preview plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";

// Import image preview and file type validation plugins
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import BuefyWrappers from "@/plugins/buefy/wrappers";

// out i18n instance
import i18n from "@/i18n";

import FIcon from "@/plugins/feather-icons/f-icon.vue";
import Breakpoint from "./breakpoints";
import VeeValidate from "./validator";

vueFilePond(
	FilePondPluginImageExifOrientation,
	FilePondPluginFileValidateType,
	FilePondPluginImageResize,
	FilePondPluginImageTransform,
	FilePondPluginImagePreview
);

setOptions({
	labelIdle: i18n.t("$filePond.labelIdle", {
		browse: `<span class='filepond--label-action has-text-weight-bold has-text-link'>${i18n.t(
			"$filePond.browse"
		)}</span>`,
	}),
	labelInvalidField: i18n.t("$filePond.labelInvalidField"),
	labelFileWaitingForSize: i18n.t("$filePond.labelFileWaitingForSize"),
	labelFileSizeNotAvailable: i18n.t("$filePond.labelFileSizeNotAvailable"),
	labelFileLoading: i18n.t("$filePond.labelFileLoading"),
	labelFileLoadError: i18n.t("$filePond.labelFileLoadError"),
	labelFileProcessing: i18n.t("$filePond.labelFileProcessing"),
	labelFileProcessingComplete: i18n.t(
		"$filePond.labelFileProcessingComplete"
	),
	labelFileProcessingAborted: i18n.t("$filePond.labelFileProcessingAborted"),
	labelFileProcessingError: i18n.t("$filePond.labelFileProcessingError"),
	labelFileProcessingRevertError: i18n.t(
		"$filePond.labelFileProcessingRevertError"
	),
	labelFileRemoveError: i18n.t("$filePond.labelFileRemoveError"),
	labelTapToCancel: i18n.t("$filePond.labelTapToCancel"),
	labelTapToRetry: i18n.t("$filePond.labelTapToRetry"),
	labelTapToUndo: i18n.t("$filePond.labelTapToUndo"),
	labelButtonRemoveItem: i18n.t("$filePond.labelButtonRemoveItem"),
	labelButtonAbortItemLoad: i18n.t("$filePond.labelButtonAbortItemLoad"),
	labelButtonRetryItemLoad: i18n.t("$filePond.labelButtonRetryItemLoad"),
	labelButtonAbortItemProcessing: i18n.t(
		"$filePond.labelButtonAbortItemProcessing"
	),
	labelButtonUndoItemProcessing: i18n.t(
		"$filePond.labelButtonUndoItemProcessing"
	),
	labelButtonRetryItemProcessing: i18n.t(
		"$filePond.labelButtonRetryItemProcessing"
	),
	labelButtonProcessItem: i18n.t("$filePond.labelButtonProcessItem"),
});

Vue.component("f-icon", FIcon);

Vue.use(Buefy, {
	defaultIconComponent: "f-icon",
	defaultIconPack: "",
});

Vue.use(BuefyWrappers);

Vue.use(VeeValidate, {
	mode: "lazy",
	events: "change|blur",
	i18nRootKey: "$veeValidate",
	i18n,
});

Vue.mixin(Breakpoint);

// Need to modify prototype so that the mixin is available
// in the data function of Vue-components.
Vue.prototype = new Vue({
	mixins: [Breakpoint],
});
