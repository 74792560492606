import config from "@/config";
import i18n from "@/i18n";

export default {
	isLoggedIn(state) {
		return state.id != null;
	},
	roleDisplayName(state) {
		switch (state.role) {
			case config.AUTH_ADMIN_ROLE:
				return i18n.t("user.adminRole");
			case config.AUTH_MECHANIC_ROLE:
				return i18n.t("user.mechanicRole");
			default:
				return i18n.t("user.unknownRole");
		}
	},
	isSupervisor(state) {
		return state.role === config.AUTH_ADMIN_ROLE;
	},
	isMechanic(state) {
		return state.role === config.AUTH_MECHANIC_ROLE;
	},
	email(state) {
		return state.email;
	},
};
