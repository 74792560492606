import Vue from "vue";
import resize from "./resize";
import scroll from "./scroll";
import abaxImage from "./abax-image";
import uppercase from "./uppercase";

Vue.directive("resize", resize);
Vue.directive("scroll", scroll);
Vue.directive("abax-image", abaxImage);
Vue.directive("uppercase", uppercase);
