<template>
	<buefy-dropdown
		ref="dropdown"
		v-bind="$attrs"
		v-on="$listeners"
		:position="effectivePosition"
		:scrollable="scrollable"
	>
		<template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope"
			><slot :name="slot" v-bind="scope"
		/></template>
	</buefy-dropdown>
</template>

<script>
	import BuefyDropdown from "buefy/src/components/dropdown/Dropdown.vue";

	export default {
		components: { BuefyDropdown },
		inheritAttrs: false,
		props: {
			noFlip: {
				type: Boolean,
				default: false,
			},
			position: {
				type: String,
				default: "is-bottom-right",
			},
			scrollable: {
				type: Boolean,
				default: true,
			},
		},
		data() {
			return {
				contentObserver: undefined,
				flipped: undefined, // true if dropdown position is bottom and would not fit in the viewport under the trigger
			};
		},
		computed: {
			effectivePosition() {
				return this.flipped
					? this.position.replace("bottom", "top")
					: this.position;
			},
		},
		created() {
			window.addEventListener("resize", this.checkFlipped);
			window.addEventListener("scroll", this.checkFlipped);
		},
		mounted() {
			this.contentObserver = new MutationObserver(() => {
				this.checkFlipped();
			});
			this.contentObserver.observe(
				this.$refs.dropdown.$el.querySelector(".dropdown-content"),
				{ childList: true }
			);
			this.checkFlipped();
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.checkFlipped);
			window.removeEventListener("scroll", this.checkFlipped);
			this.contentObserver.disconnect();
		},
		methods: {
			checkFlipped() {
				if (this.noFlip || !this.position.includes("bottom")) {
					this.flipped = false;
					return;
				}

				const dropdownMenu = this.$refs.dropdown.$el.querySelector(
					".dropdown-menu"
				);
				const {
					style: { display, visibility },
				} = dropdownMenu;
				dropdownMenu.style.display = "block";
				dropdownMenu.style.visibility = "hidden";

				const rect = this.$refs.dropdown.$el.getBoundingClientRect();
				const menuRect = dropdownMenu.getBoundingClientRect();

				this.flipped =
					rect.bottom + menuRect.height >
					(window.innerHeight ||
						document.documentElement.clientHeight);

				dropdownMenu.style.display = display;
				dropdownMenu.style.visibility = visibility;
			},
		},
	};
</script>
<style lang="scss">
	@import "@/styles/variable-exports";

	/*
    Prevent hiding dropdown menu behind top navbar
 */
	.dropdown-menu {
		z-index: 40 !important;
	}

	/*
    Prevent displaying dropdown selected item in multiline
 */
	.dropdown-trigger * {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}

	/*
    Wrap too long list items
 */
	.dropdown-content * {
		white-space: normal;
		word-wrap: break-word;
	}

	/*
    Fix short dropdown list on modal in mobile view
 */
	@media screen and (max-width: $desktop) {
		.dropdown.is-mobile-modal .dropdown-menu .dropdown-content {
			max-height: none !important;
		}
	}
</style>
