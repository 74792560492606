/* eslint-disable no-underscore-dangle */
import * as easingPatterns from "@/helpers/animation/easing-patterns";
import styleVariables from "@/styles/variable-exports.scss";

const desktopThreshold = parseInt(styleVariables["global-brk-desktop"], 10);
const bodySize = parseInt(styleVariables["global-body-size"], 10);
const navbarHeightFactor = parseFloat(styleVariables["global-navbar-height"]);
const navbarSpacedHeightFactor = parseFloat(
	styleVariables["global-navbar-spaced-height"]
);

function $(el) {
	if (typeof el === "string") {
		return document.querySelector(el);
	}
	if (el && el._isVue) {
		return el.$el;
	}
	if (el instanceof HTMLElement) {
		return el;
	}
	return null;
}

function type(el) {
	return el == null ? el : el.constructor.name;
}

function getContainer(container) {
	const el = $(container);

	if (el) return el;

	throw typeof container === "string"
		? new Error(`Container element "${container}" not found.`)
		: new TypeError(
				`Container must be a Selector/HTMLElement/VueComponent, received ${type(
					container
				)} instead.`
		  );
}

function getOffset(target) {
	if (typeof target === "number") {
		return target;
	}

	let el = $(target);
	if (!el) {
		throw typeof target === "string"
			? new Error(`Target element "${target}" not found.`)
			: new TypeError(
					`Target must be a Number/Selector/HTMLElement/VueComponent, received ${type(
						target
					)} instead.`
			  );
	}

	let totalOffset = 0;
	while (el) {
		totalOffset += el.offsetTop;
		el = el.offsetParent;
	}

	return totalOffset;
}

export function scrollTo(target, _settings = {}) {
	const settings = {
		container:
			document.scrollingElement ||
			document.body ||
			document.documentElement,
		duration: 500,
		offset: 0,
		easing: "easeInOutCubic",
		appOffset: true,
		..._settings,
	};

	if (settings.appOffset && document) {
		const isNavFixedTop = document.body.classList.contains(
			"has-navbar-fixed-top"
		);
		const isNavSpaced = document.body.classList.contains(
			"has-spaced-navbar"
		);

		const clientWidth = Math.max(
			document.documentElement.clientWidth,
			window.innerWidth || 0
		);
		const hasSlimNavbar = clientWidth < desktopThreshold;

		if (isNavFixedTop && isNavSpaced && !hasSlimNavbar) {
			settings.offset += bodySize * navbarSpacedHeightFactor;
		} else if (isNavFixedTop) {
			settings.offset += bodySize * navbarHeightFactor;
		}
	}

	const container = getContainer(settings.container);
	const startTime = performance.now();
	const targetLocation = getOffset(target) - settings.offset;
	const startLocation = container.scrollTop;
	if (targetLocation === startLocation)
		return Promise.resolve(targetLocation);

	const ease =
		typeof settings.easing === "function"
			? settings.easing
			: easingPatterns[settings.easing];
	if (!ease)
		throw new TypeError(`Easing function "${settings.easing}" not found.`);

	return new Promise((resolve) =>
		requestAnimationFrame(function step(currentTime) {
			const timeElapsed = currentTime - startTime;
			const progress = Math.abs(
				settings.duration
					? Math.min(timeElapsed / settings.duration, 1)
					: 1
			);

			container.scrollTop = Math.floor(
				startLocation +
					(targetLocation - startLocation) * ease(progress)
			);

			if (
				progress === 1 ||
				container.clientHeight + container.scrollTop ===
					container.scrollHeight
			) {
				resolve(targetLocation);
				return;
			}

			requestAnimationFrame(step);
		})
	);
}

export function scrollToInputByName(inputName, settings = {}) {
	scrollTo(`input[name="${inputName}"]`, settings);
}

export function scrollToFirstError(errorBag, settings = {}) {
	if (errorBag.count()) {
		scrollToInputByName(Object.keys(errorBag.collect())[0], {
			offset: 30, // compensate to show label
			...settings,
		});
	}
}
