import http from "@/services/http";
import config from "@/config";

export async function fetchSubdealers() {
	return http.get(`${config.API_PATH_STRUCTURE}/structure/subdealers`);
}

export async function fetchDealersForTransfer(manufacturerId) {
	if (!manufacturerId) {
		throw new Error("Please provide manufacturerId");
	}
	return http.get(
		`${config.API_PATH_STRUCTURE}/structure/equipment-transfer-targets?manufacturerId=${manufacturerId}`
	);
}

export default {
	fetchSubdealers,
	fetchDealersForTransfer,
};
