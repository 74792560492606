import parsePropsFromEquipmentQuery from "../dealer/equipment/parsePropsFromQuery";
import parsePropsFromMapQuery from "../dealer/map/parsePropsFromQuery";
import parsePropsFromMaintenanceQuery from "../dealer/maintenance/parsePropsFromQuery";

export const MECHANIC_TRACK_NEW_EQUIPMENT_ROUTE = "mechanic-track-new";
export const MECHANIC_EQUIPMENT_TRACKED_ROUTE = "mechanic-eq-tracked";
export const MECHANIC_EQUIPMENT_ROUTE = "mechanic-equipment";
export const MECHANIC_DASHBOARD_ROUTE = "mechanic-dashboard";
export const MECHANIC_MAINTENANCE_ROUTE = "mechanic-maintenance";
export const MECHANIC_MAP_ROUTE = "mechanic-map";
export const MECHANIC_DEFAULT_ROUTE = "mechanic";

export default [
	{
		path: "/mechanic/",
		name: MECHANIC_DEFAULT_ROUTE,
		component: () => import("./layout.vue"),
		redirect: { name: MECHANIC_DASHBOARD_ROUTE },
		children: [
			{
				path: "dashboard/",
				name: MECHANIC_DASHBOARD_ROUTE,
				component: () => ({
					component: import("../dealer/dashboard/index.vue"),
				}),
			},
			{
				path: "equipment/",
				name: MECHANIC_EQUIPMENT_ROUTE,
				props: (route) => parsePropsFromEquipmentQuery(route.query),
				component: () => ({
					component: import("../dealer/equipment/index.vue"),
				}),
			},
			{
				path: "map/",
				name: MECHANIC_MAP_ROUTE,
				props: (route) => parsePropsFromMapQuery(route.query),
				component: () => ({
					component: import("../dealer/map/index.vue"),
				}),
			},
			{
				path: "maintenance/",
				name: MECHANIC_MAINTENANCE_ROUTE,
				props: (route) => parsePropsFromMaintenanceQuery(route.query),
				component: () => ({
					component: import("../dealer/maintenance/index.vue"),
				}),
			},
			{
				path: "equipment/track-new",
				name: MECHANIC_TRACK_NEW_EQUIPMENT_ROUTE,
				component: () => ({
					component: import(
						"@/pages/mechanic/equipment/track-new/index.vue"
					),
				}),
			},
			{
				path: "equipment/tracked",
				name: MECHANIC_EQUIPMENT_TRACKED_ROUTE,
				component: () => ({
					component: import(
						"@/pages/mechanic/equipment/tracked/index.vue"
					),
				}),
			},
		],
	},
];
