<template>
	<div id="app" class="is-flex is-full-width">
		<router-view />
	</div>
</template>

<script>
	export default {
		name: "App",
	};
</script>
