<template>
	<svg class="feather" :class="size ? `fi-${size}` : ''">
		<use
			:xlink:href="
				require(`feather-icons/dist/feather-sprite.svg`) +
				`#${featherIconName}`
			"
		/>
	</svg>
</template>

<script>
	import mapIconName from "./map-icon-name";

	export default {
		name: "f-icon",
		props: {
			icon: { type: [Array, String], required: true },
			size: { type: String, default: "" },
		},
		computed: {
			featherIconName() {
				// The icon property can is either be a string or an array (buefy icon-component contract).
				// When it's an array, [0] = icon pack prefix, [1] = icon name.
				// Since Feather does not use prefixes we only care about the name.
				if (Array.isArray(this.icon)) {
					return mapIconName(this.icon[1]);
				}
				return mapIconName(this.icon);
			},
		},
	};
</script>

<style>
	.feather,
	.icon svg.feather {
		stroke: currentColor;
		stroke-width: 2;
		stroke-linecap: round;
		stroke-linejoin: round;
		fill: none;
	}

	.button .icon.is-small svg.feather,
	svg.feather.fi-lg {
		width: 1.1rem;
		height: 1.1rem;
	}

	svg.feather.fi-1x {
		width: 1rem;
		height: 1rem;
	}

	svg.feather.fi-2x {
		width: 2rem;
		height: 2rem;
	}

	svg.feather.fi-3x {
		width: 3rem;
		height: 3rem;
	}

	svg.feather.fi-4x {
		width: 4rem;
		height: 4rem;
	}

	svg.feather.fi-5x {
		width: 5rem;
		height: 5rem;
	}

	svg.feather.fi-6x {
		width: 6rem;
		height: 6rem;
	}

	svg.feather.fi-7x {
		width: 7rem;
		height: 7rem;
	}

	svg.feather.fi-8x {
		width: 8rem;
		height: 8rem;
	}

	svg.feather.fi-9x {
		width: 9rem;
		height: 9rem;
	}

	svg.feather.fi-10x {
		width: 10rem;
		height: 10rem;
	}
</style>
