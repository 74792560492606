function parsePropsFromQuery(query) {
	return {
		text: query.text,
		page: parseInt(query.page, 10) || 1,
		pageSize: parseInt(query.pageSize, 10) || 25,
		sort: query.sort || "name asc",
	};
}

export default parsePropsFromQuery;
