import store from "@/store";
import config from "@/config";

export function canAccessUsersList() {
	const { user } = store.state;

	if (!user || !user.role) {
		return false;
	}

	if (user.role !== config.AUTH_ADMIN_ROLE) {
		return false;
	}

	return true;
}

export function usersNavigationGuard(to, from, next) {
	const canAccess = canAccessUsersList();

	if (canAccess) {
		next();
	} else {
		next("/");
	}
}

export default {
	canAccessUsersList,
	usersNavigationGuard,
};
