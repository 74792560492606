import Vue from "vue";
import Router from "vue-router";

import authRoutes from "@/pages/auth/routes";

import dealerRoutes from "@/pages/dealer/routes";
import mechanicRoutes from "@/pages/mechanic/routes";
import manufacturerRoutes from "@/pages/manufacturer/routes";
import errorRoutes from "@/pages/error/routes";

import { scrollTo } from "@/helpers/scroll";

import requireAuthHook from "./hooks/require-auth";
import { getDefaultUserRoute } from "./user-defaults";
import RegisterGoogleAnalytics from "./RegisterGoogleAnalytics";

Vue.use(Router);

const router = new Router({
	mode: "history",
	routes: [
		{
			path: "/",
			async beforeEnter(to, from, next) {
				next(getDefaultUserRoute());
			},
		},
		...authRoutes,
		...dealerRoutes,
		...mechanicRoutes,
		...manufacturerRoutes,
		...errorRoutes,
	],
	scrollBehavior: (to, from, savedPosition) =>
		scrollTo(savedPosition ? savedPosition.y : 0),
});

RegisterGoogleAnalytics(router);

// Register hooks (executed before any route)
requireAuthHook.registerOn(router);

export default router;
