import uuidv4 from "@/helpers/uuid";

export default function mapCommand(axiosInstance) {
	async function sendCommand(url, commandPayload, metaData) {
		const meta = metaData || {};
		let commandId = meta.commandId || meta.command_id;

		if (!commandId) {
			commandId = uuidv4();
		}

		const response = await axiosInstance.put(url, commandPayload, {
			headers: {
				Accept: "application/json",
				"Content-Type": "application/json",
				"x-command-id": commandId,
			},
		});

		return {
			commandId,
			...response,
		};
	}

	return sendCommand;
}
