import http from "@/services/http";
import config from "@/config";

/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */

function updateInternal(el, binding) {
	const { value } = binding;

	if (value) {
		el.style.display = "block";
		http.get(
			`${config.API_PATH_EQUIPMENT}/storage/download-url?filename=${value}`
		).then((response) => el.setAttribute("src", response.headers.location));
	} else {
		el.removeAttribute("src");
		el.style.display = "none";
	}
}

function inserted(el, binding) {
	updateInternal(el, binding);
}

function update(el, binding) {
	if (binding.value === binding.oldValue) {
		return;
	}

	el.removeAttribute("src");
	el.style.display = "none";

	updateInternal(el, binding);
}

export default {
	inserted,
	update,
};
