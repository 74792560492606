/* eslint-disable no-underscore-dangle */
export default {
	...window.__env__,
	AUTH_CLIENT_ID: "dealer-portal",
	AUTH_PROFILE_SCOPE: "abax_profile",
	AUTH_EQUIPMENT_API_SCOPE: "eq_ctrl_api",
	AUTH_DEALER_PORTAL_API_SCOPE: "dealer_portal_api",
	AUTH_ORGANIZATION_STRUCTURE_API_SCOPE: "organization_structure_api",
	AUTH_ADMIN_ROLE: "supervisor",
	AUTH_MECHANIC_ROLE: "mechanic",
	AUTH_MANUFACTURER_ROLE: "manufacturer",
	API_BASE_URL: "/api",
	API_PATH_PORTAL: "/portal",
	API_PATH_EQUIPMENT: "/equipment",
	API_PATH_STRUCTURE: "/structure",
};
