import store from "@/store";
import { tryCatch } from "@/helpers/error";
import { GENERIC_ERROR_ROUTE } from "@/pages/error/routes";

export const AUTH_LOGIN_ROUTE = "auth-login";
export const AUTH_LOGOUT_ROUTE = "auth-logout";
export const AUTH_LOGIN_CALLBACK_ROUTE = "auth-callback";

export default [
	{
		path: "/auth/callback",
		name: AUTH_LOGIN_CALLBACK_ROUTE,
		component: () => import("./callback/index.vue"),
		meta: { allowAnonymous: true },
	},
	{
		path: "/auth/login",
		name: AUTH_LOGIN_ROUTE,
		async beforeEnter(to, from, next) {
			if (to.name === AUTH_LOGIN_ROUTE) {
				const [error, success] = await tryCatch(
					store.dispatch("user/tryRestoreSession")
				);
				if (error) {
					next({
						name: GENERIC_ERROR_ROUTE,
						query: {
							title: "Login Error",
							message: "Could not restore login session",
							details: error.message,
							sourcePath: to.fullPath,
						},
					});
					return;
				}

				if (!success) {
					return; // login is pending
				}

				next("/");
				return;
			}
			next();
		},
		meta: { allowAnonymous: true },
	},
	{
		path: "/auth/logout",
		name: AUTH_LOGOUT_ROUTE,
		async beforeEnter(to, from, next) {
			if (to.name === AUTH_LOGOUT_ROUTE) {
				const [error] = await tryCatch(store.dispatch("user/logout"));
				if (error) {
					next({
						name: GENERIC_ERROR_ROUTE,
						query: {
							title: "Logout Error",
							message: "Failed while logging you out",
							details: error.message,
							sourcePath: to.fullPath,
						},
					});
					return;
				}

				next("/");
				return;
			}
			next();
		},
		meta: { allowAnonymous: true },
	},
];
