import authService from "@/services/auth";
import mapOidcProfile from "./map-oidc-profile";

export default {
	async logout({ commit }) {
		localStorage.removeItem("dealers");
		localStorage.removeItem("dealersDataExpirationDate");
		await authService.logout();
		commit("loggedOut");
	},
	async handleAuthentication({ commit, dispatch, rootState }) {
		await authService.handleAuthenticationCallback();
		const user = await authService.getUser();
		commit("loggedIn", mapOidcProfile(user.profile));
		if (rootState.user.role === "supervisor") {
			dispatch("dealers/fetchDealerStructure", null, { root: true });
		}
	},
	async tryRestoreSession({ commit, dispatch, rootState }) {
		const [errorResponse, user] = await authService.tryRestoreSession();
		const isOidcError =
			errorResponse &&
			(errorResponse.error === "login_required" ||
				errorResponse.error === "consent_required" ||
				errorResponse.error === "interaction_required");

		if (isOidcError) {
			await authService.login();
			return false;
		}

		if (errorResponse) {
			throw errorResponse;
		}

		commit("loggedIn", mapOidcProfile(user.profile));

		if (rootState.user.role === "supervisor") {
			if (JSON.parse(localStorage.getItem("dealersDataExpirationDate"))) {
				const dealersDataExpirationDate = Number(
					localStorage.getItem("dealersDataExpirationDate")
				);
				const now = new Date().getTime();
				const timeout =
					dealersDataExpirationDate - now > 0
						? dealersDataExpirationDate - now
						: 0;
				dispatch("dealers/setDealerExpiration", timeout, {
					root: true,
				});
			}

			dispatch("dealers/fetchDealerStructure", null, { root: true });
		}

		return true;
	},
};
