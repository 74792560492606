import Vue from "vue";
import Vuex from "vuex";

import user from "./modules/user";
import dealers from "./modules/dealers";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		user,
		dealers,
	},
});
