function parsePropsFromQuery(query) {
	return {
		text: query.text,
		page: parseInt(query.page, 10) || 1,
		pageSize: parseInt(query.pageSize, 10) || 25,
		filters: {
			status: query.status,
			overdue:
				typeof query.overdue === "boolean"
					? query.overdue
					: query.overdue === "true",
		},
		sort: query.sort,
	};
}

export default parsePropsFromQuery;
