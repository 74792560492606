import isPromise from "is-promise";

export function tryCatchSync(func) {
	try {
		return [null, func()];
	} catch (err) {
		return [err];
	}
}

export function tryCatch(funcOrPromise) {
	if (typeof funcOrPromise !== "function" && !isPromise(funcOrPromise)) {
		throw new Error("Argument must be a function or Promise");
	}

	return isPromise(funcOrPromise)
		? funcOrPromise.then(
				(value) => [null, value],
				(error) => [error]
		  )
		: tryCatchSync(funcOrPromise);
}
