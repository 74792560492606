import { fetchSubdealers } from "@/components/structure/api";
import { tryCatch } from "@/helpers/error";

export default {
	async fetchDealerStructure({ commit, dispatch }) {
		if (JSON.parse(localStorage.getItem("dealers"))) {
			commit("fetchDealers", JSON.parse(localStorage.getItem("dealers")));
		} else {
			const [error, res] = await tryCatch(fetchSubdealers());
			if (error) {
				throw error;
			}
			if (!res) {
				return;
			}
			commit("fetchDealers", res.data);
			const timeout = 86400000;
			const dealersDataExpirationDate =
				new Date().getTime() + 60 * 60 * 24 * 1000;

			dispatch("setDealerExpiration", timeout);
			localStorage.setItem("dealers", JSON.stringify(res.data));
			localStorage.setItem(
				"dealersDataExpirationDate",
				JSON.stringify(dealersDataExpirationDate)
			);
		}
	},
	setDealerExpiration({ dispatch }, timeout) {
		setTimeout(() => {
			localStorage.removeItem("dealers");
			localStorage.removeItem("dealersDataExpirationDate");
			dispatch("fetchDealerStructure");
		}, timeout);
	},
};
