import VeeValidate from "vee-validate";

import validFile from "./rules/valid_file";

VeeValidate.Validator.extend("valid_file", validFile);

export const ValidatorConstants = {
	MAX_OPERATING_HOURS: 596523.23, // [HIT-588] bigger values causes exceeding of MAX_INT in the backend after conversion to seconds
	Mixin: {
		created() {
			this.ValidatorConstants = ValidatorConstants;
		},
	},
};

export default VeeValidate;
