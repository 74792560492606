export default function mapOidcProfile(profile) {
	const mappedProfile = {
		id: profile.sub,
		role: profile.role,
		name: profile.name,
		email: profile.email,
		emailVerified: profile.email_verified,
		locale: profile.locale || "en-GB",
		organizationId:
			profile["http://schemas.abax.no/identity/claims/organizationid"],
	};
	return mappedProfile;
}
