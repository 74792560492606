function parsePropsFromQuery(query) {
	return {
		filters: {
			status: query.status,
			overdue:
				typeof query.overdue === "boolean"
					? query.overdue
					: query.overdue === "true",
			text: query.text,
		},
		equipmentId: query.equipmentId,
	};
}

export default parsePropsFromQuery;
