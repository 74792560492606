import store from "@/store";
import { tryCatch } from "@/helpers/error";
import { GENERIC_ERROR_ROUTE } from "@/pages/error/routes";

export default {
	registerOn(router) {
		return router.beforeEach(async (to, from, next) => {
			const requireAuthentication = !to.matched.some(
				(record) => record.meta.allowAnonymous
			);
			if (requireAuthentication && !store.getters["user/isLoggedIn"]) {
				const [error, success] = await tryCatch(
					store.dispatch("user/tryRestoreSession", to.fullPath)
				);
				if (error) {
					next({
						name: GENERIC_ERROR_ROUTE,
						query: {
							title: "Login Error",
							message: "Could not restore login session",
							details: error.message,
							sourcePath: to.fullPath,
						},
					});
					return;
				}

				if (!success) {
					return; // login is pending
				}
			}

			next();
		});
	},
};
