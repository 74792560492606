function parsePropsFromQuery(query) {
	return {
		text: query.text,
		page: query.view === "map" ? 1 : parseInt(query.page, 10) || 1,
		pageSize: parseInt(query.pageSize, 10) || 25,
		sort: query.sort || "identifier asc",
		view: query.view || "list",
		filter: query.filter,
	};
}

export default parsePropsFromQuery;
