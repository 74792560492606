import deepMerge from "deepmerge";
import log from "@/services/logging";

export default function mapHttpMethods(axiosInstance) {
	function handleRequestError(error, request) {
		const errorData = {
			request,
			message: error.message,
		};
		if (error.response) {
			errorData.statusCode = error.response.status;
			errorData.content = error.response.data;
			errorData.headers = error.response.headers;

			if (error.response.status >= 500) {
				log.error("Request failed", errorData);
			} else {
				log.warn("There was a problem with request", errorData);
			}
		} else {
			log.error("Request failed", errorData);
		}
	}

	return {
		get(url, configuration) {
			return axiosInstance
				.get(
					url,
					deepMerge(
						{
							headers: {
								Accept: "application/json",
							},
						},
						configuration || {}
					)
				)
				.catch((error) => handleRequestError(error, `GET ${url}`));
		},

		post(url, data, configuration) {
			return axiosInstance
				.post(
					url,
					data,
					deepMerge(
						{
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
						},
						configuration || {}
					)
				)
				.catch((error) => handleRequestError(error, `POST ${url}`));
		},

		put(url, data, configuration) {
			return axiosInstance
				.put(
					url,
					data,
					deepMerge(
						{
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
						},
						configuration || {}
					)
				)
				.catch((error) => handleRequestError(error, `PUT ${url}`));
		},

		patch(url, data, configuration) {
			return axiosInstance
				.patch(
					url,
					data,
					deepMerge(
						{
							headers: {
								Accept: "application/json",
								"Content-Type": "application/json",
							},
						},
						configuration || {}
					)
				)
				.catch((error) => handleRequestError(error, `PATCH ${url}`));
		},

		delete(url, configuration) {
			return axiosInstance
				.delete(
					url,
					deepMerge(
						{
							headers: {
								Accept: "application/json",
							},
						},
						configuration || {}
					)
				)
				.catch((error) => handleRequestError(error, `DELETE ${url}`));
		},
	};
}
