/* eslint-disable no-underscore-dangle */
import Vue from "vue";

import styleVariables from "@/styles/variable-exports.scss";

const defaults = {
	thresholds: {
		tablet: parseInt(styleVariables["global-brk-tablet"], 10),
		desktop: parseInt(styleVariables["global-brk-desktop"], 10),
		widescreen: parseInt(styleVariables["global-brk-widescreen"], 10),
		fullHD: parseInt(styleVariables["global-brk-fullhd"], 10),
	},
};

function getClientDimensions() {
	return {
		clientWidth:
			typeof document === "undefined"
				? 0
				: Math.max(
						document.documentElement.clientWidth,
						window.innerWidth || 0
				  ),
		clientHeight:
			typeof document === "undefined"
				? 0
				: Math.max(
						document.documentElement.clientHeight,
						window.innerHeight || 0
				  ),
	};
}

export default Vue.extend({
	data() {
		const { clientWidth, clientHeight } = getClientDimensions();
		return {
			clientWidth,
			clientHeight,
			...defaults,
		};
	},
	computed: {
		$breakpoint() {
			const mobile = this.clientWidth < this.thresholds.tablet;
			const tablet =
				this.clientWidth < this.thresholds.desktop && !mobile;
			const desktop =
				this.clientWidth < this.thresholds.widescreen &&
				!(tablet || mobile);
			const widescreen =
				this.clientWidth < this.thresholds.fullHD &&
				!(desktop || tablet || mobile);
			const fullHD =
				this.clientWidth >= this.thresholds.fullHD &&
				!(widescreen || desktop || tablet || mobile);

			const isMobile = mobile;
			const isTablet = tablet;
			const isTabletAndDown =
				(mobile || tablet) && !(desktop || widescreen || fullHD);
			const isTabletAndUp =
				!mobile && (tablet || desktop || widescreen || fullHD);
			const isDesktopOnly = desktop;
			const isDesktopAndDown =
				(mobile || tablet || desktop) && !(widescreen || fullHD);
			const isDesktopAndUp =
				!(mobile || tablet) && (desktop || widescreen || fullHD);
			const isWidescreenOnly = widescreen;
			const isWidescreenAndDown =
				(mobile || tablet || desktop || widescreen) && !fullHD;
			const isWidescreenAndUp =
				!(mobile || tablet || desktop) && (widescreen || fullHD);
			const isFullHD = fullHD;

			let name;
			switch (true) {
				case mobile:
					name = "mobile";
					break;
				case tablet:
					name = "tablet";
					break;
				case desktop:
					name = "desktop";
					break;
				case widescreen:
					name = "widescreen";
					break;
				default:
					name = "fullHD";
					break;
			}

			const result = {
				// Definite breakpoint.
				mobile,
				tablet,
				desktop,
				widescreen,
				fullHD,

				// Useful e.g. to construct CSS class names dynamically.
				name,

				// Breakpoint ranges.
				isMobile,
				isTablet,
				isTabletAndDown,
				isTabletAndUp,
				isDesktopOnly,
				isDesktopAndDown,
				isDesktopAndUp,
				isWidescreenOnly,
				isWidescreenAndDown,
				isWidescreenAndUp,
				isFullHD,

				// For custom breakpoint logic.
				width: this.clientWidth,
				height: this.clientHeight,
			};
			return result;
		},
	},
	methods: {
		updateDimensionsForBreakpointPlugin() {
			// Cross-browser support as described in:
			// https://stackoverflow.com/questions/1248081
			const { clientWidth, clientHeight } = getClientDimensions();
			this.clientWidth = clientWidth;
			this.clientHeight = clientHeight;
		},
	},
	created() {
		window.addEventListener(
			"resize",
			this.updateDimensionsForBreakpointPlugin,
			{ passive: true }
		);
	},
	mounted() {
		this.$nextTick(() => {
			this.updateDimensionsForBreakpointPlugin();
		});
	},
	beforeDestroy() {
		if (typeof window === "undefined") return;

		window.removeEventListener(
			"resize",
			this.updateDimensionsForBreakpointPlugin
		);
	},
});
