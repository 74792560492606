import config from "@/config";
import store from "@/store";

import { AUTH_LOGOUT_ROUTE } from "@/pages/auth/routes";
import { DEALER_DEFAULT_ROUTE } from "@/pages/dealer/routes";
import { MECHANIC_DEFAULT_ROUTE } from "@/pages/mechanic/routes";
import { MANUFACTURER_DEFAULT_ROUTE } from "@/pages/manufacturer/routes";
import { GENERIC_ERROR_ROUTE } from "@/pages/error/routes";

export function getDefaultUserRoute() {
	const { user } = store.state;
	if (!user || !user.role) return { name: AUTH_LOGOUT_ROUTE }; // user data in store is corrupted, force sign-in again
	switch (user.role) {
		case config.AUTH_ADMIN_ROLE:
			return { name: DEALER_DEFAULT_ROUTE };
		case config.AUTH_MECHANIC_ROLE:
			return { name: MECHANIC_DEFAULT_ROUTE };
		case config.AUTH_MANUFACTURER_ROLE:
			return { name: MANUFACTURER_DEFAULT_ROUTE };
		default:
			return {
				name: GENERIC_ERROR_ROUTE,
				query: {
					title: "Internal Error",
					message: "User role not supported or implemented",
				},
			};
	}
}

export default {
	getDefaultUserRoute,
};
