export const MANUFACTURER_DEFAULT_ROUTE = "manufacturer";

export default [
	{
		path: "/manufacturer/",
		name: MANUFACTURER_DEFAULT_ROUTE,
		component: () => ({
			component: import("./index.vue"),
		}),
	},
];
