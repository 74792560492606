export const GENERIC_ERROR_ROUTE = "error";
export const LOGIN_ERROR_ROUTE = "login-error";

export default [
	{
		path: "/error",
		name: GENERIC_ERROR_ROUTE,
		component: () => ({
			component: import("./generic-error.vue"),
		}),
		props(route) {
			return {
				title: route.query.title || route.meta.title,
				message: route.query.message,
				details: route.query.details,
			};
		},
		meta: {
			allowAnonymous: true,
		},
	},
	{
		path: "/login-error",
		name: LOGIN_ERROR_ROUTE,
		component: () => ({
			component: import("./login-error.vue"),
		}),
		props(route) {
			return {
				details: route.query.details,
			};
		},
		meta: {
			allowAnonymous: true,
		},
	},
	// fallback route
	{
		path: "*",
		name: "page-not-found",
		meta: {
			allowAnonymous: true,
		},
		component: () => ({
			component: import("./page-not-found.vue"),
		}),
	},
];
