import i18n from "@/i18n";

/* eslint-disable no-param-reassign */
export default {
	loggedOut(state) {
		Object.assign(state, {
			id: null,
			role: null,
			name: null,
			email: null,
			emailVerified: false,
			locale: null,
			organizationId: null,
		});
	},
	loggedIn(state, user) {
		Object.assign(state, user);
		i18n.locale = user.locale;
	},
};
