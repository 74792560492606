function getToken(options) {
	const token = options.getToken();
	if (typeof token !== "object" || !token.then) {
		return Promise.resolve(token);
	}

	return token;
}

export default function bearerTokenProvider(options) {
	return function addBearerTokenInterceptor(config) {
		const requestConfig = config;
		return getToken(options).then((token) => {
			if (token) {
				requestConfig.headers.Authorization = `Bearer ${token}`;
			}
			return requestConfig;
		});
	};
}
