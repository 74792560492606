import parsePropsFromEquipmentQuery from "./equipment/parsePropsFromQuery";
import parsePropsFromMaintenanceQuery from "./maintenance/parsePropsFromQuery";
import parsePropsFromMapQuery from "./map/parsePropsFromQuery";
import parsePropsFromUsersQuery from "./users/parsePropsFromQuery";
import { usersNavigationGuard } from "./users/users-navigation-control";

export const DEALER_EQUIPMENT_ROUTE = "dealer-equipment";
export const DEALER_MAINTENANCE_ROUTE = "dealer-maintenance";
export const DEALER_TRACK_NEW_EQUIPMENT_ROUTE = "dealer-track-new-equipment";
export const DEALER_MAP_ROUTE = "dealer-map";
export const DEALER_USERS_ROUTE = "dealer-users";
export const DEALER_DASHBOARD_ROUTE = "dealer-dashboard";
export const DEALER_DEFAULT_ROUTE = "dealer";

export default [
	{
		path: "/dealer/",
		name: DEALER_DEFAULT_ROUTE,
		component: () => import("./layout.vue"),
		redirect: { name: DEALER_DASHBOARD_ROUTE },
		children: [
			{
				path: "dashboard/",
				name: DEALER_DASHBOARD_ROUTE,
				beforeEnter: (to, from, next) =>
					usersNavigationGuard(to, from, next),
				component: () => ({
					component: import("./dashboard/index.vue"),
				}),
			},
			{
				path: "equipment/",
				name: DEALER_EQUIPMENT_ROUTE,
				beforeEnter: (to, from, next) =>
					usersNavigationGuard(to, from, next),
				props: (route) => parsePropsFromEquipmentQuery(route.query),
				component: () => ({
					component: import("./equipment/index.vue"),
				}),
			},
			{
				path: "map/",
				name: DEALER_MAP_ROUTE,
				props: (route) => parsePropsFromMapQuery(route.query),
				component: () => ({
					component: import("./map/index.vue"),
				}),
			},
			{
				path: "maintenance/",
				name: DEALER_MAINTENANCE_ROUTE,
				props: (route) => parsePropsFromMaintenanceQuery(route.query),
				component: () => ({
					component: import("./maintenance/index.vue"),
				}),
			},
			{
				path: "equipment/track-new",
				name: DEALER_TRACK_NEW_EQUIPMENT_ROUTE,
				component: () => ({
					component: import(
						"@/pages/dealer/equipment/track-new/index.vue"
					),
				}),
			},
			{
				path: "users/",
				name: DEALER_USERS_ROUTE,
				beforeEnter: (to, from, next) =>
					usersNavigationGuard(to, from, next),
				props: (route) => parsePropsFromUsersQuery(route.query),
				component: () => ({
					component: import("@/pages/dealer/users/index.vue"),
				}),
			},
		],
	},
];
