import axios from "axios";
import config from "@/config";
import authService from "../auth";
import mapHttpMethods from "./map-http-methods";
import mapCommand from "./map-command";
import bearerTokenProvider from "./interceptors/bearer-token-provider";

const axiosInstance = axios.create({
	baseURL: config.API_BASE_URL,
});

// we control default headers ourselves (for this instance)
axiosInstance.defaults.headers.common = [];

axiosInstance.interceptors.request.use(
	bearerTokenProvider({
		getToken() {
			return authService.getAccessToken();
		},
	})
);

const sendCommand = mapCommand(axiosInstance);

export default {
	...mapHttpMethods(axiosInstance),
	sendCommand,
};
