import Vue from "vue";

import "@/plugins";
import "@/directives";
import log from "@/services/logging";
import config from "@/config";

import App from "./app.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import "@fontsource/roboto/latin.css";
import "@fontsource/roboto/latin-ext.css";
import "@fontsource/montserrat/latin.css";
import "@fontsource/montserrat/latin-ext.css";

import "./styles/all.scss";

if (config.ENVIRONMENT === "production") {
	const analyticsScripts = document.createElement("script");
	analyticsScripts.type = "text/javascript";
	analyticsScripts.text = `(function(h,o,t,j,a,r){
    h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
    h._hjSettings={hjid:1898383,hjsv:6};
    a=o.getElementsByTagName('head')[0];
    r=o.createElement('script');r.async=1;
    r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
    a.appendChild(r);
  })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;
	document.head.appendChild(analyticsScripts);
}

Vue.config.productionTip = false;

Vue.config.errorHandler = (err, vm, info) => {
	const data = {
		info,
		errorMessage: err.toString(),
		stack: err.stack,
	};

	log.error("An error occurred", data);
};

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
