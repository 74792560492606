import store from "@/store";

const { user } = store.state;

export default function RegisterGoogleAnalytics(router) {
	router.afterEach((to) => {
		if (!user.id) {
			return;
		}

		window.dataLayer.push({
			userId: user.id,
			userRole: user.role,
			userLocale: user.locale,
			organizationId: user.organizationId,
			path: to.path,
		});

		window.dataLayer.push({
			event: "VirtualPageview",
			virtualPageURL: to.path,
		});
	});
}
