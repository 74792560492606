import Oidc from "oidc-client";
import config from "@/config";
import { tryCatch } from "@/helpers/error";

const manager = new Oidc.UserManager({
	userStore: new Oidc.WebStorageStateStore({
		store: window.localStorage,
	}),
	authority: config.AUTH_AUTHORITY,
	client_id: config.AUTH_CLIENT_ID,
	redirect_uri: `${window.location.origin}/auth/callback`,
	response_type: "id_token token",
	scope: `openid ${config.AUTH_PROFILE_SCOPE} ${config.AUTH_EQUIPMENT_API_SCOPE} ${config.AUTH_DEALER_PORTAL_API_SCOPE} ${config.AUTH_ORGANIZATION_STRUCTURE_API_SCOPE}`,
	post_logout_redirect_uri: `${window.location.origin}/`,
	silent_redirect_uri: `${window.location.origin}/silent-login.html`,
	accessTokenExpiringNotificationTime: 10,
	automaticSilentRenew: true,
	filterProtocolClaims: true,
	loadUserInfo: true,
	monitorSession: false, // TODO: maybe look into this later (single sign out using front-channel)?
});

if (config.ENVIRONMENT !== "production") {
	Oidc.Log.logger = console;
	Oidc.Log.level = Oidc.Log.INFO;
}

export default {
	handleAuthenticationCallback() {
		return manager.signinRedirectCallback();
	},
	handleSilentAuthenticationCallback() {
		return manager.signinSilentCallback();
	},
	async tryRestoreSession() {
		const [error, user] = await tryCatch(manager.signinSilent());
		if (error || user == null) {
			return [error, null];
		}

		return [null, user];
	},
	getUser() {
		return manager.getUser();
	},
	async getAccessToken() {
		const [error, user] = await tryCatch(manager.getUser());
		if (error) return "";
		return user ? user.access_token : "";
	},
	login(state) {
		const relativePath = window.location.pathname + window.location.search;
		if (relativePath !== "/") {
			sessionStorage.setItem("redirect_url", relativePath);
		}
		return manager.signinRedirect(state);
	},
	logout() {
		return manager.signoutRedirect();
	},
};
